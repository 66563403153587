export enum subscriberSourceEnum {
    SOURCE_HOMEPAGE_SUBSCRIBE_SECTION = 'homepage_subscribe_section',
    SOURCE_LEARNDROPS_LANDING_PAGE = 'learndrops_landing_page',
    SOURCE_HOSTINGER_BF_LANDING_PAGE = 'hostinger_bf_landing_page',
    SOURCE_PRIMEXBT_DEAL_LANDING_PAGE = 'primexbt_deal_landing_page',
    SOURCE_NEWSLETTER = 'newsletter',
    SOURCE_NEWSLETTER_LEARNDROPS = 'newsletter_learndrops',
    SOURCE_NEWSLETTER_ARTICLE = 'newsletter_article',
    SOURCE_NEWSLETTER_FOOTER = 'newsletter_footer',
    SOURCE_NEWSLETTER_LANDING = 'newsletter_landing_page',
    SOURCE_NEWSLETTER_POPUP = 'newsletter_popup',
}
